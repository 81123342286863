<template>
  <div>
    <br />
    <div class="row row-sm">
      <div class="col-12 col-md-6 col-lg-3 col-xl-3">
        <label for="">
          {{ $t("sale7") }}
          <span class="text-danger">*</span>
        </label>

        <vue-select 
         :options="customers"
          v-model="filter.customer_reference"
          placeholder="Select an option"
          labelColumn="fullName"
          valueColumn="reference"
      />
        <!-- <select
          v-model="filter.customer_reference"
          class="form-select"
          required
        >
          <option selected value="--">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
            :style="
              customer.blocked || customer.fullName.includes('Bloqué')
                ? { color: 'red' }
                : ''
            "
          >
            {{ customer.fullName }} {{ customer.society }}
          </option>
        </select> -->

      </div>

      <div class="col-12 col-md-6 col-lg col-xl">
        <label for="">
          {{ $t("sale8") }} <span class="text-danger">*</span>
        </label>
        <input v-model="filter.startDate" type="date" class="form-control" />
      </div>
      <div class="col-12 col-md-6 col-lg col-xl">
        <label for="">
          {{ $t("sale9") }} <span class="text-danger">*</span>
        </label>
        <input v-model="filter.endDate" type="date" class="form-control" />
      </div>

      <div class="col-12 col-md-6 col-lg-2 col-xl-2">
        <label for=""> {{ $t("sale10") }} </label>
        <select v-model="filter.paymentStatus" class="form-select">
          <option value="--">--</option>
          <option value="Payé">Payé</option>
          <option value="Impayé">Impayé</option>
        </select>
      </div>
    </div>
    <br />
    <div class="row justify-content-end">
      <div class="col-12 col-md-6 col-lg-auto col-xl-auto">
        <button @click="toFilter(filter)" class="btn btn-secondary">
          <i class="bi bi-funnel"></i>
          {{ $t("sale11") }}
        </button>
      </div>

      <div class="col-12 col-md-6 col-lg-auto col-xl-auto">
        <button @click="refresh()" class="btn btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          {{ $t("sale12") }}
        </button>
      </div>

      <div class="col-12 col-md-6 col-lg-auto col-xl-auto">
        <button @click="printMany(selections)" class="btn btn-secondary">
          <i class="bi bi-printer"></i>
          Imprimer BL
        </button>
      </div>

      <div class="col-12 col-md-6 col-lg-auto col-xl-auto">
        <button
          @click="$router.push({ name: 'sales-items-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-person-plus"></i>
          {{ $t("sale13") }}
        </button>
      </div>

    </div>
    <br />
    <vue-data-table 
      :headers="headers" 
      :data="sales" 
      :items-per-page="10" 
      :buttons="buttons"
      :summable-columns="summableColumns"
    />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// import SaleDataTableVue from "../../components/cards/SaleDataTable.vue";
export default {
  // components: { SaleDataTableVue },
  data() {
    return {
      filter: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
      selections: [],
      headers: [
        { field: 'reference', label: 'Référence',class:'' },
        { field: 'customerFullName', label: 'Client',class:'' },
        { field: 'date', label: 'Date',class:'' },
        { field: 'totalPriceTTC', label: 'Total TTC',class:'text-primary' },
        { field: 'totalQuantity', label: 'Quantité Totale',class:'' },
        { field: 'amountPaid', label: 'Montant payé',class:'text-success' },
        { field: 'amountUnpaid', label: 'Montant impayé',class:'text-danger' },
        { field: 'userName', label: 'Utilisateur',class:'' },
      ],
      buttons: [
        {
          label: 'Détails',
          icon: 'bi bi-info-square',
          class: 'btn btn-primary',
          action: (sale) => {
            this.$router.push({
              name: 'sales-details',
              params: { reference: sale.reference },
            });
          },
        },
      ],
      summableColumns: [
        { field: 'totalPriceTTC', class: 'text-primary' },
        { field: 'totalQuantity', class: '' },
        { field: 'amountPaid', class: 'text-success' },
        { field: 'amountUnpaid', class: 'text-danger' }
      ],
    };
  },
  computed: { 
    ...mapGetters("sale", {
      sales: "getAll",
      totalQuantity: "getSalesTotalQuantity",
      totalPriceTTC: "getSalesTotalPriceTTC",
      totalPaid: "getSalesTotalPaid",
      totalUnpaid: "getSalesTotalUnpaid",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  created() {
    this.$store.dispatch("customer/getAll");
    this.$store.dispatch("sale/fetchSales");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("sale/search", value);
    },
    async refresh() {
      await this.$store.dispatch("sale/fetchSales");
      await this.$store.dispatch("customer/getAll");
    },

    async toFilter(data) {
      await this.$store.dispatch("sale/filter", data);
    },
    async printMany(data) {
      await this.$store.dispatch("sale/printManyBill", data);
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
};
</script>
